import React, { useState, useEffect } from 'react';

import Navbar from '../navbar/Navbar';
import Tabbar from '../navbar/Tabbar';
import Categoria from '../navbar/Categoria';
import Producto from './Producto';

import * as Constants from '../../constants/global';

import {ReactComponent as Search} from '../../assets/icon/search.svg';

const Catalogo = () => {

    let empresa = JSON.parse(localStorage.getItem('empresa'));

    const [search, setSearch] = useState();
    const [categoria, setCategoria] = useState();
    const handleCategoria = item => {
        setCategoria(item);
    }
    
    const handleCantidad = () => {
        getCantidad();
    }

    const [totalNavbar, setTotalNavbar] = useState();
    const [cantidad, setCantidad] = useState();


    const getCantidad = () => {

        Constants.calcularDescuento()
        let carrito = JSON.parse(localStorage.getItem(empresa.codigo_empresa)) || {};

        let iva = 0;
        let total = 0;
        let descuentoTotal = 0;
        let cantidad = 0;

        Object.keys(carrito).map((item, i) => {

            cantidad += carrito[item]["cantidad"];

            let subtotal = (carrito[item]["cantidad"] * carrito[item]["precio"]);
            let descuento = subtotal * (carrito[item]["porcentajeDescuento"] ? carrito[item]["porcentajeDescuento"] / 100 : 0 )
            iva += ( (subtotal - descuento) * (carrito[item]["iva"] ? carrito[item]["iva"] / 100 : 0) );

            descuentoTotal += descuento
        })

        setCantidad(cantidad)

        Object.keys(carrito).map((item, i) => {
            total += carrito[item]["cantidad"] * carrito[item]["precio"];
        })

        setTotalNavbar(total - descuentoTotal + iva);
    }

    const handleKeyPress = (e) => {
        if(e.key === "Enter"){
            handleBuscarProducto()
        }
    }

    const handleBuscarProducto = () => {

        let busqueda = document.getElementById("search").value
        setSearch(busqueda)

    }

    useEffect(() => {
        getCantidad();
    }, [])

    return (
        <React.Fragment>
            <div className="navbar">
                <div className="navbar-header">
                    <Navbar item={3} cantidad={cantidad} totalNavbar={totalNavbar} />
                    <div className="input-search">
                        <input 
                            type="text" 
                            placeholder="Buscar producto..."
                            name="search"
                            id="search"
                            onKeyPress={handleKeyPress}
                            // onChange={(e) => setSearch(e.target.value)}
                            // value={search}>
                            >
                        </input>
                    </div>
                    <div className="button-Search">
                        <button onClick={() => handleBuscarProducto()}><Search className="svg-una-capa" style={{width:"25px", height:"25px"}}/></button>
                    </div>
                </div>
                <Categoria handleCategoria={handleCategoria}/>
            </div>

            <Producto tipo={1} categoria={categoria} search={search} handleCantidad={handleCantidad} />

            <Tabbar item={3}/>
        </React.Fragment>
    )
}

export default Catalogo;