import React from 'react';
import { withRouter } from "react-router-dom";

import close from '../../assets/icon/close_grey.svg';

import * as Constants from '../../constants/global';

const DetallePedidoModal = (props) => {

    let empresa = JSON.parse(localStorage.getItem('empresa'));

    const handleClose = () => {
        document.querySelector(".modal-sucursal").classList.remove("show-modal");
    }

    return (
        <div className="modal modal-sucursal">
            <div className="modal-content">       
                <div className="title">
                    <p>Detalle Pedido</p>
                    <span className="close-button" onClick={() => handleClose()}>
                        <img src={close} width="15" height="15" alt="icon close"/>
                    </span>  
                </div>
                <div className="content full-content" >
                    <div className="list-items">
                        { props.detalle ? Object.keys(props.detalle).map((item, i) => (
                            <div className="item" style={{cursor:"default"}} key={i} >
                                <p><b>Producto: {props.detalle[item].nombre_producto ? props.detalle[item].nombre_producto : props.detalle[item].nombre}</b></p>
                                <p>Valor Unit: {Constants.copFormat(props.detalle[item].precio)}
                                    {/* {new Intl.NumberFormat("es-CO", Constants.formatConfig).format(props.detalle[item].precio)} */}
                                    
                                </p>
                                <p>Cantidad: {props.detalle[item].cantidad}</p>
                                <p><b>Total: {Constants.copFormat(props.detalle[item].cantidad * props.detalle[item].precio)}
                                    {/* {new Intl.NumberFormat("es-CO", Constants.formatConfig).format(props.detalle[item].cantidad * props.detalle[item].precio)} */}
                                    
                                </b></p>
                            </div>
                        )) : null }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(DetallePedidoModal);