import React, { useState, useEffect } from 'react';
// import { NetworkInfo } from "react-native-network-info";

import { withRouter } from "react-router-dom";
import * as Constants from '../../constants/global';
import axios from 'axios';
import Progress from '../util/Progress';
import Auth from '../../helpers/Auth';

import { isMobile }  from './../../helpers/Device';

import logo from '../../assets/img/logo.png';
import logo_login from '../../assets/img/logo_login.png';
import maqueta from '../../assets/img/maqueta.png';
import subtitulo from '../../assets/img/subtitulo.png';

import SolicitudActivacionModal from '../modal/SolicitudActivacionModal';
import CodigoActivacionModal from '../modal/CodigoActivacionModal';

const Login = ({history, props}) => {

    const [nit, setNit] = React.useState('');
    const [loader, setLoader] = React.useState(false);
    const [error, setError] = React.useState("");
    const [ipv4, setIpv4] = React.useState("");

    const [validacion, setValidacion] = useState({Telefonos: []});
    const [empresa, setEmpresa] = React.useState("");

    const publicIp = require('public-ip');
 
    const getIpv4 = async () => {
        setIpv4(await publicIp.v4());        
    };

    useEffect(() => {
        // setNit("890900608");
        getIpv4();
    })

    // CONSULTA LAS EMPRESAS
    const handleSubmit = event => {
        if(nit.length >= 6){
            setLoader(true);
            let url = Constants.URL_BASE + Constants.WSEMPRESA + "?nit=" + nit;
            axios.get(url)
            .then(resEmpresa => {
                if(resEmpresa.data.length > 0){

                    setEmpresa(resEmpresa.data)

                    let data = {
                        "Nit": nit,
                        "DeviceId": ipv4,
                        "DeviceType": "Web"
                    };

                    url = Constants.URL_BASE + Constants.WSVALIDARCLIENTE;
                    axios.post(url, data)
                    .then(res => {
                        setLoader(false);

                        setValidacion(res.data);
                        
                        if(res.data.Activo == 0){
                            document.querySelector(".modal-activacion").classList.toggle("show-modal");
                        } else {
                            Auth.authenticate(() => {
                                let dataEmpresa = {
                                    nit: nit,
                                    empresas: resEmpresa.data
                                }

                                localStorage.setItem('codigoRegistro', JSON.stringify(res.data.Codigo));
                                localStorage.setItem('empresas', JSON.stringify(dataEmpresa));
                                history.push('/');
                            })
                        }

                    });
                }else{
                        setLoader(false);
                        setError("No se encontraron coicidencias con este NIT.");
                    }
                });
        }else{
            setLoader(false);
            setError("El NIT debe contener al menos (6) digitos.");
        }
        
        event.preventDefault();
    }

    setTimeout(function(){
        if(document.querySelector(".login-content")){
            document.querySelector(".login-content").classList.add("show");
        }
    }, 3000);
    





    // VISTAS
    return (
        <div className="login">
            <div className="login-content">
                <div className="splash" id="splash">
                    <div className="logo-img">
                        <img src={logo} alt="logo app"></img>
                    </div>
                    <div className="maqueta-img">
                        <img src={maqueta} alt="logo app"></img>
                    </div>
                    <div className="footer">
                        <div className="footer-text">
                            <p>Celuweb desde hace varios meses venia trabajando en la siguiente iniciativa, y quire poner a disposición de sus clientes como una contingencia la plataforma Emart</p>
                        </div>
                        <div className="footer-img">
                            <img src={subtitulo} alt="logo app"></img>
                        </div>
                    </div>
                </div>
                <div className="login-form" id="login-form">
                    <div className="logo-img">
                        <img src={logo_login} alt="logo app"></img>
                    </div>
                    { loader ? <Progress color={"#bf0811"} /> : (
                        <form onSubmit={handleSubmit}>
                            <div className="form-control">
                                <label>NIT:</label>
                                <input 
                                    type="number"
                                    autocomplete="off"
                                    placeholder="NIT sin digito de verificación"
                                    name="nit"
                                    id="nit"
                                    onChange={e => setNit(e.target.value)}
                                    value={nit}>
                                </input>
                                { error.length > 0 ? (
                                    <span className="error">
                                        <p>{error}</p>
                                    </span>
                                ) : null }
                                
                            </div>
                            <button type="submit" className="btn-large">Ingresar</button>
                        </form>
                    )}
                    { validacion.Telefonos ? 
                        <SolicitudActivacionModal validacion={validacion} nit={nit} empresa={empresa}  /> :(
                            null
                        )
                    }
                    <CodigoActivacionModal validacion={validacion} empresa={empresa}  />

                </div>
            </div>
        </div>
        // <div className="login">
        //     <div className="login-content">
        //         <div className="logo-img">
        //             <img src={logo} alt="logo app"></img>
        //         </div>
        //         <div>
        //             { loader ? <Progress color={"#ffffff"} /> : (
        //                 <form onSubmit={handleSubmit}>
        //                     <div className="form-control">
        //                         <label>NIT:</label>
        //                         <input 
        //                             type="number"
        //                             placeholder="NIT sin digito de verificación"
        //                             name="nit"
        //                             id="nit"
        //                             onChange={e => setNit(e.target.value)}
        //                             value={nit}>
        //                         </input>
        //                     </div>
        //                     <button type="submit" className="btn-large">Ingresar</button>
        //                 </form>
        //             )}
        //             <div className="footer">
        //                 <p><b>Powered by Celuweb</b></p>
        //                 <p>Version 1.0.0</p>
        //             </div>
        //         </div>
        //     </div>
        // </div>
    )
}


export default withRouter(Login);