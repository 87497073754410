import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import logo from '../../assets/img/logo.png';
import pedido from '../../assets/icon/pedido.svg';
import sugerido from '../../assets/icon/sugerido.svg';
import catalogo from '../../assets/icon/catalogo.svg';
import excel from '../../assets/icon/excel.svg';
//import perfil from '../../assets/icon/perfil.svg';
import carrito from '../../assets/icon/carrito.svg';

const Tabbar = (props) => {

    const handleRedirect = item => {
        props.history.replace(item, "urlhistory");
    }

    return (
        <div className="tabmenu">
            <div className="tab-content">
                <div className={props.item == 1 ? "tab active" : "tab"} 
                    onClick={ () => handleRedirect('/pedido')}>
                    <img src={pedido} alt="icon menu" width="20px" height="20px"></img>
                    <span>Pedido</span>
                </div>
                <div className={props.item == 2 ? "tab active" : "tab"}
                    onClick={ () => handleRedirect('/sugerido')}>
                    <img src={sugerido} alt="icon menu" width="20px" height="20px"></img>
                    <span>Sugerido</span>
                </div>
                <div className={props.item == 3 ? "tab active" : "tab"}
                    onClick={ () => handleRedirect('/catalogo')}>
                    <img src={catalogo} alt="icon menu" width="20px" height="20px"></img>
                    <span>Catalogo</span>
                </div>
                <div className={props.item == 4 ? "tab active" : "tab"}
                    onClick={ () => handleRedirect('/excel')}>
                    <img src={excel} alt="icon menu" width="20px" height="20px"></img>
                    <span>Excel</span>
                </div>
                <div className={props.item == 5 ? "tab active" : "tab"}
                    onClick={ () => handleRedirect('/carrito')}>
                    <img src={carrito} alt="icon menu" width="20px" height="20px"></img>
                    <span>Carrito</span>
                </div>
                {/* <div className="tab">
                    <img src={peso} alt="icon menu" width="15px" height="30px"></img>
                    <span>Sugerido</span>
                </div> */}
            </div>
        </div>
    )
}


export default withRouter(Tabbar);