import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import * as Constants from '../../constants/global';

import SucursalModal from '../modal/SucursalModal';

import noimage from '../../assets/img/noimage.png';
import { cambiarTema } from '../../App.js';
import {ReactComponent as Back} from '../../assets/icon/back.svg';


const Home = (props) => {
    const [empresas, setEmpresas] = useState();
    const [empresa, setEmpresa] = useState({sucursales: []});

    useEffect(() => {
        const empresasData = async () => {
            let dataObject = JSON.parse(localStorage.getItem('empresas'));
            setEmpresas(dataObject.empresas);
        };
        empresasData();
    }, [])

    const handleBack= item => {
        props.history.goBack();
    }

    const handleEmpresa = (item) => {
        if(item.sucursales.length > 1){
            localStorage.setItem('empresa', JSON.stringify(item));
            setEmpresa(item);
            document.querySelector(".modal-sucursal").classList.toggle("show-modal");
        }else{
            item["sucursal"] = item.sucursales[0];
            localStorage.setItem('empresa', JSON.stringify(item));
            if (item.num_empresa == Constants.num_empresa_ramo){
                props.history.push('/catalogo');
            } else {
                props.history.push('/pedido');
            }
            
        } 
        if (item.num_empresa == Constants.num_empresa_ramo){
            Constants.cargarListasDescuento(item.url, item.path, item.codigo_empresa, item.sucursal.codigo);
            Constants.cargarGruposDescuento(item.url, item.path, item.codigo_empresa, item.sucursal.codigo);
        }

        cambiarTema(item);
    }

    return (
        <React.Fragment>
            <div className="navbar">
                <div className="nav-header">
                    <div className="nav-content">
                        <div className="subnav" style={{width:"100%"}}>
                            <div className="back" onClick={() => handleBack()} style={{margin:"5px"}}>
                                <Back className="svg-una-capa" style={{width:"15px", height:"15px"}}/>
                            </div>
                            <div className="nav-logo" style={{width:"85%"}}>
                                <h4>Empresas</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="content">
                {empresas ? (
                    <div className="empresas">
                        {empresas.map((item, i) => (
                            <div className="card" key={i} onClick={() => handleEmpresa(item)}>
                                <img 
                                    src={'https://emartwebapi.celuwebdev.com/logo/'+item.codigo_empresa+'/'+item.codigo_empresa+'.png'}
                                    onError={(e)=>{e.target.onerror = null; e.target.src=noimage}}>
                                </img>
                            </div>
                        ))}
                    </div>
                ) : null}
            </div>
            <SucursalModal empresa={empresa} />
        </React.Fragment>
    )
}


export default withRouter(Home);