import React, { useState, useEffect, useContext } from 'react';

import * as Constants from '../../constants/global';
import axios from 'axios';

//import { AppContext } from '../../context/AppState';

import Progress from '../util/Progress';

import {ReactComponent as Minus} from '../../assets/icon/minus.svg';
import {ReactComponent as Plus} from '../../assets/icon/plus.svg';

const Lista = (props) => {

    const [carrito, setCarrito] = useState();
    const [loader, setLoader] = React.useState(false);
    const [productos, setProductos] = useState();

    let empresa = JSON.parse(localStorage.getItem('empresa'));

    // TRAE TODOS LOS PRODUCTOS
    const loadProductos = async () => {
        let url = (empresa.url ? (empresa.url.replace("http://sfa.grupochocolates.com/", "https://sfa.grupochocolates.com/") + (empresa.path ? empresa.path + "/" : "")) :
                    Constants.URL_BASE) +
                    Constants.WSSUGERIDO +
                    "?origen=" + empresa.codigo_empresa + 
                    "&cliente=" + empresa.sucursal.codigo;

        axios.get(url)
        .then(res => {
            setLoader(false);
            setProductos(res.data);
        });

        props.handleCantidad();
    }

    // AUMENTA LA CANTTIDAD
    const handleMas = item => {    

        if(carrito[item.codigo]){
            carrito[item.codigo]["cantidad"] += 1;
        }else{
            carrito[item.codigo] = {
                nombre: item.Descripcion ? item.Descripcion : item.nombre,
                precio: parseFloat(item.precio),
                iva: parseInt(item.iva),
                cantidad: 1
            }
        }
        localStorage.setItem(empresa.codigo_empresa, JSON.stringify(carrito));
        setCarrito(carrito);
        loadProductos();
    }

    const handleMenos = item => {
        if(carrito[item.codigo]){
            if(carrito[item.codigo]["cantidad"] == 1){
                delete carrito[item.codigo];
            }else{
                carrito[item.codigo]["cantidad"] -= 1;
            }
        }
        localStorage.setItem(empresa.codigo_empresa, JSON.stringify(carrito));
        setCarrito(carrito);
        loadProductos();
    }

    const hadleTodos = (vlue) => {
        if(vlue){
            productos.map((item, i) => {
                if(carrito[item.codigo]){
                    carrito[item.codigo]["cantidad"] += parseInt(item.cantidad);
                }else{
                    carrito[item.codigo] = {
                        nombre: item.Descripcion ? item.Descripcion : item.nombre,
                        precio: item.precio,
                        iva: parseInt(item.iva),
                        cantidad: parseInt(item.cantidad)
                    }
                }
            })
        } else {
            productos.map((item, i) => {
                if(carrito[item.codigo]){
                    delete carrito[item.codigo];
                }
            })
        } 

        localStorage.setItem(empresa.codigo_empresa, JSON.stringify(carrito));
        setCarrito(carrito);
        loadProductos();
    }

    const handleChangeCantidad = (item, valor) => {
        if(valor <= 0){
            delete carrito[item.codigo];
        }else{
            if(carrito[item.codigo]){
                carrito[item.codigo]["cantidad"] = parseInt(valor);
            }else{
                carrito[item.codigo] = {
                    nombre: item.Descripcion ? item.Descripcion : item.nombre,
                    precio: item.precio,
                    iva: parseInt(item.iva),
                    cantidad: parseInt(valor)
                }
            }
        }
        localStorage.setItem(empresa.codigo_empresa, JSON.stringify(carrito));
        setCarrito(carrito);
        loadProductos();
    }
 
    // SE EJECUTA CUANDO CAMBIA CATEGORIA O BUSQUEDA
    useEffect(() => {
        setLoader(true);
        setCarrito(JSON.parse(localStorage.getItem(empresa.codigo_empresa)) || {});
        loadProductos();
    }, [])

    // VISTA
    return (
        
        <div className="pedido-list sugerido">
            { loader ? <Progress color={"#bf0811"} /> : (
                <React.Fragment>
                    {productos ? (
                        <div className="product-list">
                            <div className="checkbox">
                                <label class="switch">
                                    <input type="checkbox"
                                        onClick={ e => hadleTodos(e.target.checked)}
                                    />
                                    <span class="slider round"></span>
                                </label>
                                <label>Seleccionar todos</label>
                            </div>
                           
                            {productos.map((item, i) => (
                                <div className="item" key={i}>
                                    <p className="nombre">{item.Descripcion ? item.Descripcion : item.nombre}</p>
                                    <p className="codigo"><b>Codigo:</b> {item.codigo} - <b>EAN:</b> {item.ean}</p>
                                    <p className="precio">
                                        {/* {new Intl.NumberFormat("es-CO", Constants.formatConfig).format(item.precio * (1 + ((item.iva ? item.iva : 0) / 100))) } */}
                                        {Constants.copFormat(item.precio * (1 + ((item.iva ? item.iva : 0) / 100)))}

                                    </p>
                                    <div className="action">
                                        { carrito[item.codigo] ? (
                                            <div className="controls">
                                                <button role="button" onClick={() => handleMenos(item)}>
                                                    {/* <img src={minus} alt="icon minus" width="15px" height="15px"></img> */}
                                                    <Minus className="svg-una-capa" style={{width:"15px", height:"15px"}}/>
                                                </button>
                                                <input type="number" 
                                                    onChange={e => handleChangeCantidad(item, e.target.value)}
                                                    value={carrito[item.codigo].cantidad}/>
                                                <button role="button" onClick={() => handleMas(item)}>
                                                    {/* <img src={plus} alt="icon plus" width="15px" height="15px"></img> */}
                                                    <Plus className="svg-una-capa" style={{width:"15px", height:"15px"}}/>
                                                </button>
                                            </div>
                                        ) : (
                                            <button className="agregar" onClick={() => handleMas(item)}>Agregar</button>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : null}
                </React.Fragment>
            )}
        </div>
    )
}

export default Lista;