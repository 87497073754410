import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";

import close from '../../assets/icon/close_grey.svg';

const SucursalModal = (props) => {

    // let empresa = JSON.parse(localStorage.getItem('empresa'));
    // const [empresa, setEmpresa] = useState({sucursales: []});

    // useEffect(() => {
    //     const empresasData = async () => {
    //         let dataObject = JSON.parse(localStorage.getItem('empresa'));
    //         setEmpresa(dataObject);
    //     };
    //     empresasData();
    // }, [])

    const handleClose = () => {
        document.querySelector(".modal-sucursal").classList.remove("show-modal");
    }

    const handleSucursal = (item) => {
        let empresa = JSON.parse(localStorage.getItem('empresa'));
        empresa["sucursal"] = item;
        localStorage.setItem('empresa', JSON.stringify(empresa));
        props.history.push('/pedido');
    }

    return (
        <div className="modal modal-sucursal">
            <div className="modal-content">       
                <div className="title">
                    <p>¿Donde quieres que enviemos tu pedido?</p>
                    <span className="close-button" onClick={() => handleClose()}>
                        <img src={close} width="15" height="15" alt="icon close"/>
                    </span>  
                </div>
                <div className="content full-content">
                    <div className="list-items">
                        { props.empresa.sucursales.map((item, i) => (
                            <div className="item" key={i} onClick={() => handleSucursal(item)}>
                                <p><b>{item.ciudad + " " + item.direccion}</b></p>
                                <p>{item.razon_social}</p>
                                <p>Tel. {item.telefono}</p>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(SucursalModal);