import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import close from '../../assets/icon/close_grey.svg';
import Auth from '../../helpers/Auth';

import * as Constants from '../../constants/global';

import {ReactComponent as Rigth} from '../../assets/icon/rigth.svg';
import axios from 'axios';

// import close from '../../assets/icon/close_grey.svg';

const CodigoActivacionModal = (props) => {

    const [codigoActivacion, setCodigoActivacion] = React.useState(''); 
    const [errorCodigo, setErrorCodigo] = React.useState("");
    const [mensajeLabel, setMensajeLabel] = React.useState("");

    const [ischecked, setIsChecked] = React.useState();

    // let empresa = JSON.parse(localStorage.getItem('empresa'));
    // const [empresa, setEmpresa] = useState({sucursales: []});

    const handleClose = () => {
        document.querySelector(".modal-codigoactivacion").classList.remove("show-modal");
    }

    const handleConfirmarCodigo = (data) => {
        let url = Constants.URL_BASE + Constants.WSACTIVARCLIENTE;
        axios.post(url, data)
        .then(res => {

            if(res.data.Activado == 1){
                alert(res.data.Mensaje);
                document.querySelector(".modal-codigoactivacion").classList.remove("show-modal");
                handleLogin();
            } else {
                alert(res.data.Mensaje);
            }
        });
    }

    useEffect(() => {
    })

    const handleActivacion = () => {

            let data = {
                "Codigo": props.validacion.Codigo,
                "CodigoVerficacion": codigoActivacion,
            };

            handleConfirmarCodigo(data)
    }

    const handleLogin = () => {
        Auth.authenticate(() => {
            let dataEmpresa = {
                nit: props.nit,
                empresas: props.empresa
            }
            localStorage.setItem('empresas', JSON.stringify(dataEmpresa));
            props.history.push('/');
        })
    }

    const handleCheck = (event) => {
        let isActive = event.target.checked;
        setIsChecked(isActive);
    }

    return (

        <div className="modal modal-codigoactivacion">
            <div className="modal-content">       
                <div className="title">
                    <span className="close-button" onClick={() => handleClose()}>
                        <img src={close} width="15" height="15" alt="icon close"/>
                    </span>  
                    <p>Activar Cuenta</p>
                    <p style={{color:"black", fontWeight:"unset", fontSize:"13px"}}>Por favor ingresa el código de activación que te hemos enviado.</p>
                </div>
                <div className="content sm no-padding">
                    <input 
                        type="number"
                        autoComplete="off"
                        onChange={e => setCodigoActivacion(e.target.value)}
                        value={codigoActivacion}>
                    </input>
                    { errorCodigo.length > 0 ? (
                        <span className="error">
                            <p>{errorCodigo}</p>
                        </span>
                    ) : null }
                    <div style={{display:"flex"}}>
                        <input type="checkbox" onChange={(event) => handleCheck(event)} style={{width:"15%"}} />
                        <a href="https://emartwebapi.celuwebdev.com/TERMINOSYCONDICIONESEMART.pdf" style={{margin:"auto"}} target="_blank" >Acepto Politica de Privacidad</a>
                    </div>
                </div>
                
                <div className="footer">
                    <button type="submit" className="btn-large" disabled={( typeof(ischecked) !== "undefined" ? (ischecked && codigoActivacion.length ==7 ? false : true) : true)} onClick={() => handleActivacion()} >Enviar Código</button>
                </div>
            </div>
        </div>
    )
}

export default withRouter(CodigoActivacionModal);