import React, { useState, useEffect, useImperativeHandle } from 'react';
import Navbar from '../navbar/Navbar';
import Tabbar from '../navbar/Tabbar';
import $ from 'jquery'
import * as Constants from '../../constants/global';

import axios from 'axios';

import Progress from '../util/Progress';

//Leer Excel
import {ExcelRenderer, OutTable} from 'react-excel-renderer';

//descargar excel
import ReactExport from "react-export-excel";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Excel = () => {

    const [loader, setLoader] = React.useState(false);

    const [totalNavbar, setTotalNavbar] = useState();
    const [cantidad, setCantidad] = useState();

    const [carrito, setCarrito] = useState();
    const [productos, setProductos] = useState();

    const [arrayProducto, setArrayProductos] = useState({result:[]});


    let empresa = JSON.parse(localStorage.getItem('empresa'));
    var columns=['Código','Cantidad']

    const uploadFile= async (event)=>{

        let fileObj = event.target.files[0];

        ExcelRenderer(fileObj, (err, resp) => {
            if(err){
                // console.log(err);            
            }
            else{

                if(resp.cols.length == 2){
                    if (resp.rows[0][0] == "Código" && resp.rows[0][1] == "Cantidad") {

                        setLoader(true);

                        Object.keys(resp.rows).map((item => UsersFormatter(item, resp, resp.rows.length-1)))

                    } else {
                        alert("Los encabezados de las columnas no coinciden con las del archivo original");
                    }
                } else {
                    alert("El archivo cargado no coincide con la estructura del archivo original");
                }
            }
        }); 

    }

    function get_user(url){
        // return this promise
        return axios.get(url).then((res)=>{
            return res.data
        })
    }
    
    const UsersFormatter = async (item, resp, limit, array) => {

        let url = (empresa.url ? (empresa.url.replace("http://sfa.grupochocolates.com/", "https://sfa.grupochocolates.com/") + (empresa.path ? empresa.path + "/" : "")) :
                            Constants.URL_BASE) +
                            Constants.WSPRODUCTO +
                            "?origen=" + empresa.codigo_empresa + 
                            "&cliente=" + empresa.sucursal.codigo;

        var codProducto = resp.rows[item][0].toString().trim();
        
        if (item != 0) {
            url += "&search=" + codProducto;

            return await get_user(url)
            .then((data) => {

                if (!isNaN(codProducto)) {

                    if (data.result.length > 0) {
                        data.result[0].cantidad = resp.rows[item][1]
                    }

                    if(arrayProducto){
                        
                        if(arrayProducto.result){
                            let combine = arrayProducto.result.concat(data.result);
                            arrayProducto.result = combine;
                        }
                    } else {
                        setArrayProductos(data);
                    } 
                    setProductos(arrayProducto.result);
                    if(item == limit){
                        setLoader(false)
                    }
                } else {
                    if(item == limit){
                        setLoader(false)
                    }
                }

            });
        }else {
            if(item == limit){
                setLoader(false)
            }
            return null
        }
        
    }

    const getCantidad = () => {

        Constants.calcularDescuento()
        let carrito = JSON.parse(localStorage.getItem(empresa.codigo_empresa)) || {};

        let iva = 0;
        let total = 0;
        let descuentoTotal = 0;
        let cantidad = 0;

        Object.keys(carrito).map((item, i) => {

            cantidad += carrito[item]["cantidad"];

            let subtotal = (carrito[item]["cantidad"] * carrito[item]["precio"]);
            let descuento = subtotal * (carrito[item]["porcentajeDescuento"] ? carrito[item]["porcentajeDescuento"] / 100 : 0 )
            iva += ( (subtotal - descuento) * (carrito[item]["iva"] ? carrito[item]["iva"] / 100 : 0) );

            descuentoTotal += descuento
        })

        Object.keys(carrito).map((item, i) => {
            total += carrito[item]["cantidad"] * carrito[item]["precio"];
        })

        setCantidad(cantidad)
        setTotalNavbar(total - descuentoTotal + iva);
    }

    const handleCargarCarrito = () =>{
        
        if(productos){
            productos.map((item, i) => {

                if(carrito[item.codigo]){
                    carrito[item.codigo]["cantidad"] = parseInt(item.cantidad);
                }else{
                    carrito[item.codigo] = {
                        nombre: item.nombre,
                        precio: item.precio,
                        cantidad: parseInt(item.cantidad)
                    }
                }
            })

            localStorage.setItem(empresa.codigo_empresa, JSON.stringify(carrito));
            setCarrito(carrito);
            getCantidad();
        }
    }

    useEffect(() => {
        setCarrito(JSON.parse(localStorage.getItem(empresa.codigo_empresa)) || {});
        getCantidad();
    }, [])

    return (
        <React.Fragment>
            <div className="navbar principal">
                <Navbar item={4} cantidad={cantidad} totalNavbar={totalNavbar} />
            </div>
            <div className="pedido-list sugerido">
                <div className="product-list">
                    <div className="excel">
                        <form >
                            <div className="align-button"> 
                                <div className="custom-file">
                                    <label htmlFor="cargarExcel">Cargar excel</label>                                               
                                    <input id="cargarExcel" onChange={(e) => uploadFile(e)} type="file" className="left" /> 
                                </div>
                                <ExcelFile element={<a id="descargarPLantilla" className="button download">Descargar plantilla</a>}>
                                    <ExcelSheet data={columns} name="Leaves">
                                        <ExcelColumn label="Código" />
                                        <ExcelColumn label="Cantidad" />
                                    </ExcelSheet>
                                </ExcelFile>
                                <div>
                                    <a id="agregarCarrito" className="button right" onClick={() => handleCargarCarrito()}>Agregar al carrito</a>
                                </div>
                            </div> 
                        </form>
                    </div>
                    { loader ? <Progress color={"#bf0811"} /> : (
                        <React.Fragment>
                            {productos ? (
                                productos.map((item, i) => (
                                        <div className="item" key={i}>
                                            <p className="nombre">{item.nombre}</p>
                                            <p className="codigo"><b>Codigo:</b> {item.codigo} - <b>EAN:</b> {item.ean}</p>
                                            <p className="cantidad"><b>Cantidad:</b> {item.cantidad}</p>
                                            <p className="precio">
                                                {/* {new Intl.NumberFormat("es-CO", Constants.formatConfig).format(item.precio * (1 + ((item.iva ? item.iva : 0) / 100)))} */}
                                                {Constants.copFormat(item.precio * (1 + ((item.iva ? item.iva : 0) / 100))) }
                                            </p>
                                        </div>
                                ))
                            ) : null}
                        </React.Fragment>
                    )}
                </div>  
            </div>

            <Tabbar item={4}/>
        </React.Fragment>
    )
}

export default Excel;