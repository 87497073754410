import React, { useState, useEffect } from 'react';

import Navbar from '../navbar/Navbar';
import Tabbar from '../navbar/Tabbar';
import axios from 'axios';

import DetallePedidoModal from '../modal/DetallePedidoModal';

import * as Constants from '../../constants/global';

const UltimosPedidos = () => {

    let empresa = JSON.parse(localStorage.getItem('empresa'));

    const [totalNavbar, setTotalNavbar] = useState();
    const [cantidad, setCantidad] = useState();
    const [ultimosPedidos, setUltimosPedidos] = useState();
    
    const [detalle, setDetalle] = useState();

    const loadUltimosPedidos = async () => {
        let url = (empresa.url ? (empresa.url.replace("http://sfa.grupochocolates.com/", "https://sfa.grupochocolates.com/") + (empresa.path ? empresa.path + "/" : "")) :
                    Constants.URL_BASE) +
                    Constants.WSPEDIDO +
                    "?origen=" + empresa.codigo_empresa + 
                    "&cliente=" + empresa.sucursal.codigo;

        axios.get(url)
        .then(res => {
            
            setUltimosPedidos(res.data);

        });
    }
    
    const getCantidad = () => {

        Constants.calcularDescuento()
        let carrito = JSON.parse(localStorage.getItem(empresa.codigo_empresa)) || {};

        let iva = 0;
        let total = 0;
        let descuentoTotal = 0;
        let cantidad = 0;

        Object.keys(carrito).map((item, i) => {

            cantidad += carrito[item]["cantidad"];

            let subtotal = (carrito[item]["cantidad"] * carrito[item]["precio"]);
            let descuento = subtotal * (carrito[item]["porcentajeDescuento"] ? carrito[item]["porcentajeDescuento"] / 100 : 0 )
            iva += ( (subtotal - descuento) * (carrito[item]["iva"] ? carrito[item]["iva"] / 100 : 0) );

            descuentoTotal += descuento
        })

        setCantidad(cantidad)

        Object.keys(carrito).map((item, i) => {
            total += carrito[item]["cantidad"] * carrito[item]["precio"];
        })

        setTotalNavbar(total - descuentoTotal + iva);
    }

    const handleDetallePedido = (item) => {
        setDetalle(item)
        document.querySelector(".modal-sucursal").classList.toggle("show-modal");
    }

    useEffect(() => {
        loadUltimosPedidos();
        getCantidad();
    })

    return (
        <React.Fragment>
            <div className="navbar principal">
                <Navbar item={7} cantidad={cantidad} totalNavbar={totalNavbar} />
            </div>

            <div className="pedido-list sugerido">
                {ultimosPedidos ? (
                    <div className="product-list">
                        {Object.keys(ultimosPedidos).map((item, i) => (
                            <div className="item item-carrito" key={i} style={{cursor:"pointer"}} onClick={() => handleDetallePedido(ultimosPedidos[item].detalle)}>
                                <div className="start">
                                    <p className="nombre">Numero Documento: {ultimosPedidos[item].numero_doc}</p>
                                    <p className="nombre">Fecha: {ultimosPedidos[item].fecha.replace("T", " ")}</p>
                                    <p className="precio">Total: 
                                        {/* {new Intl.NumberFormat("es-CO", Constants.formatConfig).format(Object.values(ultimosPedidos[item].detalle).reduce((r, { precio, cantidad, iva }) => r + (precio * cantidad) * (1 + ( (iva ? iva : 0) / 100)) , 0))} */}
                                        {Constants.copFormat(Object.values(ultimosPedidos[item].detalle).reduce((r, { precio, cantidad, iva }) => r + (precio * cantidad) * (1 + ( (iva ? iva : 0) / 100)) , 0))}
                                    </p>
                                </div>
                                <div className="end">
                                </div>
                            </div>
                        ))}
                    </div>
                ) : null}
            </div>

            <Tabbar item={7}/>

            <DetallePedidoModal detalle={detalle}/>
            
        </React.Fragment>
    )
}


export default UltimosPedidos;