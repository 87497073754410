import React, { useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';
import { withRouter } from "react-router-dom";

import Navbar from '../navbar/Navbar';
import Tabbar from '../navbar/Tabbar';

import * as Constants from '../../constants/global';

import {ReactComponent as Minus} from '../../assets/icon/minus.svg';
import {ReactComponent as Plus} from '../../assets/icon/plus.svg';
import {ReactComponent as Trash} from '../../assets/icon/eliminar.svg';
import error from '../../assets/icon/error.svg';


const Carrito = (props) => {

    const [carrito, setCarrito] = useState();
    const [total, setTotal] = useState(0);
    const [iva, setIva] = useState(0);
    const [maximoPedido, setMaximoPedido] = useState(false);
    const [loader, setLoader] = React.useState(false);
    const [descuento_total, setDescuento_total] = useState(0);

    const [totalNavbar, setTotalNavbar] = useState();
    const [cantidad, setCantidad] = useState();

    const [ischecked1, setIsChecked1] = React.useState(false);
    const [ischecked2, setIsChecked2] = React.useState(false);

    // const [listasDescuento, setListasDescuento] = useState();
    // const [gruposDescuento, setGruposDescuento] = useState();
    let empresa = JSON.parse(localStorage.getItem('empresa'));
    let codigoRegistro = JSON.parse(localStorage.getItem('codigoRegistro'));

    const getCantidad = () => {

        let carrito = JSON.parse(localStorage.getItem(empresa.codigo_empresa)) || {};

        let iva = 0;
        let total = 0;
        let descuentoTotal = 0;
        let cantidad = 0;

        Object.keys(carrito).map((item, i) => {

            cantidad += carrito[item]["cantidad"];

            let subtotal = (carrito[item]["cantidad"] * carrito[item]["precio"]);
            let descuento = subtotal * (carrito[item]["porcentajeDescuento"] ? carrito[item]["porcentajeDescuento"] / 100 : 0 )
            iva += ( (subtotal - descuento) * (carrito[item]["iva"] ? carrito[item]["iva"] / 100 : 0) );

            descuentoTotal += descuento
        })

        setCantidad(cantidad)

        Object.keys(carrito).map((item, i) => {
            total += carrito[item]["cantidad"] * carrito[item]["precio"];
        })

        setTotalNavbar(total - descuentoTotal + iva);
    }

    const loadHistorial = async () => {
        let url = Constants.URL_BASE +
                    Constants.WSHISTORIAL +
                    "?codigo=" + empresa.codigo_empresa + 
                    "&cliente=" + empresa.sucursal.codigo;

        axios.get(url)
        .then(res => {
            if(res.data.length >= empresa.max_pedidos){
                setMaximoPedido(true);
            }else{
                setMaximoPedido(false);
            }
        });
    }

    const getIva = () => {

        let descuento = Constants.calcularDescuento()

        setDescuento_total(descuento ? descuento : 0)

        let carrito = JSON.parse(localStorage.getItem(empresa.codigo_empresa)) || {};

        let iva = 0;
        Object.keys(carrito).map((item, i) => {
            let subtotal = (carrito[item]["cantidad"] * carrito[item]["precio"]);
            let descuento = subtotal * (carrito[item]["porcentajeDescuento"] ? carrito[item]["porcentajeDescuento"] / 100 : 0 )
            iva += ( (subtotal - descuento) * (carrito[item]["iva"] ? carrito[item]["iva"] / 100 : 0) );
        })
        setIva(iva);
    }

    const getTotal = () => {


        let carrito = JSON.parse(localStorage.getItem(empresa.codigo_empresa)) || {};

        let total = 0;
        Object.keys(carrito).map((item, i) => {
            total += carrito[item]["cantidad"] * carrito[item]["precio"];
        })

        setTotal(total);

        /***--------- Fin Calculo Subtotal ----------****/

    }

    // AUMENTA LA CANTTIDAD
    const handleMas = (codigo, item) => {    
        if(carrito[codigo]){
            carrito[codigo]["cantidad"] += 1;
        }else{
            carrito[codigo] = {
                nombre: item.nombre,
                precio: item.precio,
                cantidad: 1
            }
        }
        localStorage.setItem(empresa.codigo_empresa, JSON.stringify(carrito));
        setCarrito(carrito);
        getIva();
        getTotal();
        getCantidad();
    }

    const handleEliminar = (codigo, item) => {  

        if(carrito[codigo]){
            delete carrito[codigo];
        }  

        localStorage.setItem(empresa.codigo_empresa, JSON.stringify(carrito));
        setCarrito(carrito);
        getIva();
        getTotal();
        getCantidad();
    }

    const handleMenos = (codigo, item)  => {
        if(carrito[codigo]){
            if(carrito[codigo]["cantidad"] == 1){
                delete carrito[codigo];
            }else{
                carrito[codigo]["cantidad"] -= 1;
            }
        }
        localStorage.setItem(empresa.codigo_empresa, JSON.stringify(carrito));
        setCarrito(carrito);
        getIva();
        getTotal();
        getCantidad();
    }

    const handleCancelarPedido = () => {
        Object.keys(carrito).map((item, i) => {
            delete carrito[item];
        });

        localStorage.setItem(empresa.codigo_empresa, JSON.stringify(carrito));

        if (empresa.num_empresa == Constants.num_empresa_ramo){
            props.history.replace("/catalogo", "urlhistory");
        } else {
            props.history.replace("/pedido", "urlhistory");
        }
    }

    const enviarPedido = () => {
        let data = [];
        let count = 0;
        
        let numeroDoc = moment().format('YYYYMMDDHHmmssSSS');

        if (empresa.num_empresa == Constants.num_empresa_ramo){
            Object.keys(carrito).map((item, i) => {
                count++;
                data.push({
                    "NumeroDoc": numeroDoc,
                    "CodigoCliente": empresa.sucursal.codigo,
                    "CodigoProveedor": empresa.num_empresa,
                    "FechaMovil": moment().format('YYYY-MM-DD HH:mm'),
                    "Precio": parseInt(carrito[item]["precio"]),
                    "Iva": carrito[item]["iva"] ? parseInt(carrito[item]["iva"]) : 0,
                    "Posicion": count,
                    "Cantidad": parseInt(carrito[item]["cantidad"]),
                    "CodigoProducto": item,
                    "ValorDescuento": carrito[item]["descuentoAplicado"] ? carrito[item]["descuentoAplicado"] : 0,
                    "Param1": carrito[item]["porcentajeDescuento"] ? carrito[item]["porcentajeDescuento"] : 0,
                    "Param2": carrito[item]["cantidadDescuento"] ?  parseInt(carrito[item]["cantidadDescuento"]) : 0,
                    "Param3": carrito[item]["codigoDescuento"] ? parseInt(carrito[item]["codigoDescuento"]) : 0,
                    "Param4": carrito[item]["clase"] ? carrito[item]["clase"] : "",
                    "DescripcionParam1": carrito[item]["porcentajeDescuento"] ? "Porcentaje Descuento" : "",
                    "DescripcionParam2": carrito[item]["cantidadDescuento"] ? "Cantidad Descuento Aplicada" : "",
                    "DescripcionParam3": carrito[item]["codigoDescuento"] ? "Codigo Descuento" : "",
                    "DescripcionParam4": carrito[item]["clase"] ? "Clase" : ""
    
                })
            });
        } else {
            Object.keys(carrito).map((item, i) => {
                count++;
                data.push({
                    "NumeroDoc": numeroDoc,
                    "CodigoCliente": empresa.sucursal.codigo,
                    "CodigoProveedor": empresa.num_empresa,
                    "FechaMovil": moment().format('YYYY-MM-DD HH:mm'),
                    "Precio": parseInt(carrito[item]["precio"]),
                    "Iva": carrito[item]["iva"] ? parseInt(carrito[item]["iva"]) : 0,
                    "Posicion": count,
                    "Cantidad": parseInt(carrito[item]["cantidad"]),
                    "CodigoProducto": item,
                    "ValorDescuento": carrito[item]["descuentoAplicado"] ? carrito[item]["descuentoAplicado"] : 0,
                })
            });
        }

        if(data.length > 0){

            let url = Constants.URL_BASE + Constants.WSPEDIDO + "?codigo=" + empresa.codigo_empresa + "&codUsuario="+ codigoRegistro;

            axios.post(url, {"ListaDetalle": data})
            .then(res => {

                alert(res.data.Mensaje ? res.data.Mensaje : "Pedido Almacenado Exitosamente");

                Object.keys(carrito).map((item, i) => {
                    delete carrito[item];
                });

                localStorage.setItem(empresa.codigo_empresa, JSON.stringify(carrito));

                if (empresa.num_empresa == Constants.num_empresa_ramo){
                    props.history.replace("/catalogo", "urlhistory");
                } else {
                    props.history.replace("/pedido", "urlhistory");
                }
            });
        }
    }

    const handlePedido = () => {

        let fechaActual = new Date(); 

        let hora = fechaActual.getHours(); 
        let minutos = fechaActual.getMinutes(); 

        empresa.hora_limite = (empresa.hora_limite ? empresa.hora_limite : "")

        if(empresa.hora_limite == "") {
            enviarPedido()
        } else if (parseInt(hora+''+minutos) <= parseInt(empresa.hora_limite.replace(":","")) ){
            enviarPedido()
        } else {
            alert("Su pedido no puede ser registrado en este momento.\nRecuerde que la hora máxima para enviar sus pedidos es: " + empresa.hora_limite +"")
        }
    }

    const handleChangeCantidad = (codigo, item, valor) => {
        if(valor <= 0){
            delete carrito[codigo];
        }else{
            if(carrito[codigo]){
                carrito[codigo]["cantidad"] = parseInt(valor);
            }else{
                carrito[codigo] = {
                    nombre: item.nombre,
                    precio: item.precio,
                    cantidad: parseInt(valor)
                }
            }
        }
        localStorage.setItem(empresa.codigo_empresa, JSON.stringify(carrito));
        setCarrito(carrito);
        getIva();
        getTotal();
        getCantidad();
    }

    const handleCheckTerminos1 = (event) => {
        let isActive = event.target.checked;
        setIsChecked1(isActive);
    }

    const handleCheckTerminos2 = (event) => {
        let isActive = event.target.checked;
        setIsChecked2(isActive);
    }

    useEffect(() => {
        // if (empresa.num_empresa == Constants.num_empresa_ramo) {
            // Constants.cargarListasDescuento(empresa.url, empresa.path, empresa.codigo_empresa, empresa.sucursal.codigo);
            // Constants.cargarGruposDescuento(empresa.url, empresa.path, empresa.codigo_empresa, empresa.sucursal.codigo);
        // }

        setCarrito(JSON.parse(localStorage.getItem(empresa.codigo_empresa)) || {});
        getIva();
        getTotal();
        loadHistorial();
        getCantidad();

    }, [])
    
    return (
        <React.Fragment>
            <div className="navbar principal">
                <Navbar item={5} cantidad={cantidad} totalNavbar={totalNavbar}/>
            </div>

{/* {
    listasDescuento ? (
        <div>
            {
                listasDescuento.map((item_general, x)=>(
                        <p>{item_general.codDescuento}</p>
                    )
                )
            }
        </div>
    ) : null
} */}

            <div className="pedido-list carrito">
                {carrito ? (
                    <div className="product-list">
                        {Object.keys(carrito).map((item, i) => (
                            <div className="item item-carrito" key={i}>
                                <div className="start">
                                    <p className="nombre">{carrito[item].nombre}</p>
                                    <p className="precio">
                                        {/* {new Intl.NumberFormat("es-CO", Constants.formatConfig).format(carrito[item].precio * (1 + ((carrito[item].iva ? carrito[item].iva : 0) / 100)))} */}
                                        {Constants.copFormat(carrito[item].precio * (1 + ((carrito[item].iva ? carrito[item].iva : 0) / 100)))}
                                    </p>
                                    <p className="regular">
                                        Precio regular 
                                        {/* {" " + new Intl.NumberFormat("es-CO", Constants.formatConfig).format(carrito[item].precio * (1 + ((carrito[item].iva ? carrito[item].iva : 0) / 100))) + " x " + carrito[item].cantidad}  */}
                                        {" " + Constants.copFormat(carrito[item].precio * (1 + ((carrito[item].iva ? carrito[item].iva : 0) / 100))) + " x " + carrito[item].cantidad}

                                    </p>
                                </div>
                                <div className="end">
                                    <div className="action">
                                        <div className="controls">
                                            <button role="button" onClick={() => handleMenos(item, carrito[item])}>
                                                {/* <img src={minus} alt="icon minus" width="15px" height="15px"></img> */}
                                                <Minus className="svg-una-capa" style={{width:"15px", height:"15px"}}/>
                                            </button>
                                            <input type="number" 
                                                    onChange={e => handleChangeCantidad(item, carrito[item], e.target.value)}
                                                    value={carrito[item].cantidad} />
                                            <button role="button" onClick={() => handleMas(item, carrito[item])}>
                                                {/* <img src={plus} alt="icon plus" width="15px" height="15px"></img> */}
                                                <Plus className="svg-una-capa" style={{width:"15px", height:"15px"}}/>
                                            </button>
                                        </div>
                                        <a style={{cursor:"pointer"}} onClick={() => handleEliminar(item, carrito[item])}>
                                            <Trash className="svg-dos-capas" style={{width:"20px"}}/>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                ) : null}
            </div>


            <div className="total">

                <div className="total-content">
                    <div className="item">
                        <p className="opc">Cliente:</p>
                        <p className="val">
                            {empresa["sucursal"].nombre}
                        </p>
                    </div>
                    <div className="item">
                        <p className="opc">Razon Social:</p>
                        <p className="val">
                            {empresa["sucursal"].razon_social}
                        </p>
                    </div>
                    <div className="item">
                        <p className="opc">NIT:</p>
                        <p className="val">
                            {empresa["sucursal"].codigo}
                        </p>
                    </div>
                    <div className="item">
                        <p className="opc">Dirección:</p>
                        <p className="val">
                            {empresa["sucursal"].direccion}
                        </p>
                    </div>
                </div>

                <br/>

                <div className="total-content">
                    <div className="item">
                        <p className="opc">Subtotal:</p>
                        <p className="val">
                            {/* {new Intl.NumberFormat("es-CO", Constants.formatConfig).format(total)} */}
                            {Constants.copFormat(total) }
                        </p>
                    </div>

                    { empresa.num_empresa == Constants.num_empresa_ramo ? (
                        <div className="item">
                            <p className="opc">Descuento:</p>
                            <p className="val">
                                {/* {new Intl.NumberFormat("es-CO", Constants.formatConfig).format(descuento_total)} */}
                                {Constants.copFormat(descuento_total) }
                            </p>
                        </div>
                    ) : null }

                    <div className="item">
                        <p className="opc">Iva:</p>
                        <p className="val">
                            {/* {new Intl.NumberFormat("es-CO", Constants.formatConfig).format(iva)} */}
                            {Constants.copFormat(iva) }
                        </p>
                    </div>
                    <div className="item">
                        <p className="opc">Total:</p>
                        <p className="val">
                            {/* {new Intl.NumberFormat("es-CO", Constants.formatConfig).format((total - descuento_total + iva))} */}
                            {Constants.copFormat(total - descuento_total + iva) }
                        </p>
                    </div>
                </div>

                <br/>
                { (empresa.label_condiciones1 != "" && empresa.url_condiciones1 != "") || (empresa.label_condiciones2 != "" && empresa.url_condiciones2 != "") ? (
                    <React.Fragment>
                        <div className="total-content">
                            { (empresa.label_condiciones1 != "" && empresa.url_condiciones1 != "") ? (
                                <React.Fragment>
                                    <div>
                                        <input type="checkbox" onChange={(event) => handleCheckTerminos1(event)} style={{width:"15%"}} />
                                        <a href={empresa.url_condiciones1} style={{margin:"auto"},{textDecoration:"underline"}} target="_blank" >{empresa.label_condiciones1}</a>
                                    </div>
                                </React.Fragment>
                            ) : null}
                            { (empresa.label_condiciones2 != "" && empresa.url_condiciones2 != "") ? (
                                <React.Fragment>
                                    <div>
                                        <input type="checkbox" onChange={(event) => handleCheckTerminos2(event)} style={{width:"15%"}} />
                                        <a href={empresa.url_condiciones2} style={{margin:"auto"},{textDecoration:"underline"}} target="_blank" >{empresa.label_condiciones2}</a>
                                    </div>
                                </React.Fragment>
                            ) : null}
                        </div>
                    </React.Fragment>
                ) : null}
                
                <button type="submit" className="btn-large outline" onClick={() => handleCancelarPedido()}>Cancelar pedido</button>
                
                {maximoPedido ? (
                    <div className="carrito-validacion">
                        <div>
                            <img src={error} alt="icon plus" width="20px" height="20px"></img>
                        </div>
                        <div className="contenido">
                            <p><b>Cantidad maxima de pedidos superada</b></p>
                            <p>Solo se permiten maximo ({empresa.max_pedidos}) pedidos por día.</p>
                        </div>
                    </div>
                ) : (
                    <React.Fragment>
                        {empresa.pedido_minimo > (total + iva) ? (
                            <div className="carrito-validacion">
                                <div>
                                    <img src={error} alt="icon plus" width="20px" height="20px"></img>
                                </div>
                                <div className="contenido">
                                    <p><b>Pedido Mínimo</b></p>
                                    <p>Los pedidos deben ser mayor a  
                                        {/* {" " + new Intl.NumberFormat("es-CO", Constants.formatConfig).format(empresa.pedido_minimo )} */}
                                        {" " + Constants.copFormat(empresa.pedido_minimo) }
                                    </p>
                                </div>
                            </div>
                        ) : (

                            <button type="submit" className="btn-large" 
                                disabled={((((empresa.label_condiciones1 != "" && empresa.url_condiciones1 !="" && !ischecked1) || (empresa.label_condiciones1 != "" && empresa.url_condiciones1 !="" && !ischecked2))) ? true : false)}
                                onClick={() => handlePedido()}>Enviar pedido</button>
                        )}
                    </React.Fragment>
                )}   
            </div>

            <Tabbar item={5}/>
        </React.Fragment>
    )
}

export default withRouter(Carrito);