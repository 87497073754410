import React, { useState, useEffect } from 'react';
import { withRouter } from "react-router-dom";
import close from '../../assets/icon/close_grey.svg';
import Auth from '../../helpers/Auth';

import * as Constants from '../../constants/global';

import {ReactComponent as Rigth} from '../../assets/icon/rigth.svg';
import axios from 'axios';

// import close from '../../assets/icon/close_grey.svg';

const SolicitudActivacionModal = (props) => {

    const [correo, setCorreo] = React.useState(''); 
    const [errorCorreo, setErrorCorreo] = React.useState("");

    // let empresa = JSON.parse(localStorage.getItem('empresa'));
    // const [empresa, setEmpresa] = useState({sucursales: []});

    const handleClose = () => {
        document.querySelector(".modal-activacion").classList.remove("show-modal");
    }

    const handleEnviarCodigo = (data) => {
        let url = Constants.URL_BASE + Constants.WSENVIARCODIGO;
        axios.post(url, data)
        .then(res => {

            if(res.data.Estado == "OK"){
                alert(res.data.Messaje);

                document.querySelector(".modal-activacion").classList.remove("show-modal");
                document.querySelector(".modal-codigoactivacion").classList.toggle("show-modal");
            }
        });
    }

    const handleTelefono = (item) => {
        let data = {
            "Codigo": props.validacion.Codigo,
            "Telefono": item,
        };

        
        if(window.confirm("¿Desea desea enviar el Mensaje a este número *" + item.substring(item.length - 4, item.length) + "?")){
            handleEnviarCodigo(data)
        }

    }

    const handleCorreo = () => {
        var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        if(! correo.match(mailformat)) {
            setErrorCorreo("El correo ingresado no es válido")
        } else {
            setErrorCorreo("")

            let data = {
                "Codigo": props.validacion.Codigo,
                "Email": correo,
            };
            handleEnviarCodigo(data)
        }
    }

    return (
        <div className="modal modal-activacion">
            <div className="modal-content">       
                <div className="title">
                    <span className="close-button" onClick={() => handleClose()}>
                        <img src={close} width="15" height="15" alt="icon close"/>
                    </span>  
                    <p>Para usar nuestros servicios Emart, debes activarte...</p>
                    <p style={{color:"black", fontWeight:"unset", fontSize:"13px"}}>Por favor elige a que número te enviaremos el código de activación</p>
                </div>
                <div className="content sm borderline">
                    <div className="list-items telefonos">
                        { props.validacion.Telefonos.map((item, i) => (
                            <div className="item borderline" key={i} onClick={() => handleTelefono(item)}>
                                <p className="centerAlign"><b>*{item.substring(item.length - 4, item.length)}</b> <Rigth className="svg-una-capas" style={{width:"10px", height:"10px"}}/></p>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="content sm no-padding">
                    <p style={{fontSize:"13px", textAlign:"center"}}>Te enviaremos un SMS para activar tu cuenta</p>
                    <hr></hr>
                    <p style={{fontSize:"13px", textAlign:"center"}}>O actívate con tu correo electrónico</p>

                    <input 
                        type="email"
                        placeholder="E-mail"
                        onChange={e => setCorreo(e.target.value)}
                        value={correo}>
                    </input>
                    { errorCorreo.length > 0 ? (
                        <span className="error">
                            <p>{errorCorreo}</p>
                        </span>
                    ) : null }
                </div>
                
                <div className="footer">
                    <button type="submit" className="btn-large" onClick={() => handleCorreo()}>Enviar Código</button>
                </div>
            </div>
        </div>
    )
}

export default withRouter(SolicitudActivacionModal);